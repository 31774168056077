<template>
  <div class="icon-manage-container" id="iconManageContainer">
    <div class="headerDetail">
      <ds-header title="图标顺序"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form layout="inline">
      <a-form-item label="项目名称：">
        <a-select style="width: 200px" 
          v-model="searchForm.projectName" 
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          @change="onChange"
        >
          <a-select-option v-for="item in projectName" :key="item.id" :value="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px"> 查询 </a-button>
      </span>
    </a-form>
    <div class="content">
      <div class="leftBtn" @click="leftBtn">
        <span class="leftBtnClass"> &lt; </span>
      </div>
      <div class="iconContent">
        <div class="icon" v-for="item in this.iconList" :key="item.id">
          <div class="imgIcon">
            <img :src="item.serviceIconUrl">
            <span>{{item.serviceName}}</span>
            <a-input v-model="inputText"></a-input>
          </div>
        </div>
      </div>
      <div class="rightBtn">
        <span class="rightBtnClass"> &gt; </span>
      </div>
    </div>
    <div class="submitClass">
      <a-button type="primary">保存</a-button>
    </div>
  </div>
</template>

<script>
import { projectName, serviceListById } from "@/api/serviceBinding";

export default {
  data() {
    return {
      searchForm: {
        projectName: "",
      },
      serviceId: '',
      inputText: '',
      // 项目名称
      projectName: [],
      iconList: []
    };
  },
  created() {
    this.projectNameList()
  },
  methods: {

    // 左页切换
    leftBtn(){
      console.log('1')
    },

    // 选择项目下拉框获取项目id
    onChange(value){
      this.serviceId = value
    },
    
    // 查询按钮 
    async search() {
      const res = await serviceListById({projectId: this.serviceId})
      console.log(res, '项目id')
      if(res.code == 200){
        this.iconList = res.data
      }
    },

    // 获取项目名称
    async projectNameList() {
      const res = await projectName();
      console.log(res, "项目名称");
      if (res.code == 200) {
        this.projectName = res.data.items;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.icon-manage-container {
  height: 70vh;
}

.submitClass{
  width:100%; 
  text-align: center; 
}

.imgIcon{
  width: 50%;
  height: 50%;
  img{
    width: 60%;
    height: 80%;
  }
}

.icon{
  width: 270px;
    height: 200px;
    border: 1px solid black;
    margin-left: 10px;
    margin-top: 10px;
}

.iconContent {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  height: 70%;
  margin-left: auto
}

.content {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  margin-bottom: 25px;
}

.rightBtn {
  position: relative;
  color: gray;
  border: 1px solid gray;
  text-align: center;
  width: 3%;
  height: 30%;
  font-size: 20px;
  cursor:pointer;
  line-height: 100%;
  .rightBtnClass {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
  }
}

.leftBtn {
  position: relative;
  color: gray;
  border: 1px solid gray;
  text-align: center;
  width: 3%;
  height: 30%;
  font-size: 20px;
  cursor:pointer;
  line-height: 100%;
  .leftBtnClass {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
  }
}

.headerDetail {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
</style>
